//
//
//
//
//
//
//
//
//

import LayoutDefault from "~/components/01_templates/LayoutDefault.vue";
import LayoutVectary from "~/components/01_templates/LayoutVectary.vue";
import LayoutModelviewer from "~/components/01_templates/LayoutModelviewer.vue";
import server from "~/helper/server.js";

export default {
  name: "PageTemplate",
  head() {
    return server.pageHead(this.story);
  },
  data() {
    return {
      story: { content: {} }
    };
  },
  components: {
    LayoutDefault,
    LayoutVectary,
    LayoutModelviewer
  },
  async asyncData(context) {
    const version = server.getVersion(context);
    const fullSlug = server.getSlug(context);

    const page = await server.storyblokApi(
      context.app,
      version,
      fullSlug,
      context
    );

    return {
      story: page
    };
  }
};
